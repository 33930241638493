import { CartLineItemFragment } from '@modules/commercetools'
import { ProductKey } from '@modules/spoonflower/models/productKey'
import {
  CustomFieldDictionary,
  VariantAttributeDictionary,
} from '../hooks/useLineItemAttributes'

const getItemName = (
  lineItem: CartLineItemFragment,
  variantAttributes: VariantAttributeDictionary,
  customFields: CustomFieldDictionary
) => {
  const { designName } = customFields
  const { productKey } = lineItem

  const productCategory = variantAttributes['category']?.value
  const productName = `${productCategory} ${designName?.value ?? ''}`.trim()

  switch (productKey) {
    case ProductKey.Subscriptions:
      return variantAttributes['subscription-name']?.value ?? ''
    default:
      return productName
  }
}

export default getItemName
